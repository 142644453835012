export const setOpenArticle = (object) => {
  return {
    type: "SET_OPEN_ARTICLE",
    payload: object,
  };
};

export const setScrollElement = (name) => {
  return {
    type: "SET_SCROLL_ELEMENT",
    payload: name,
  };
};

export const setArticlesLoaded = (loaded) => {
  return {
    type: "SET_ARTICLES_LOADED",
    payload: loaded,
  };
};

export const setArticles = (articles) => {
  return {
    type: "SET_ARTICLES",
    payload: articles,
  };
};
