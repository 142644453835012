import React, { useEffect } from "react";

//import { useRouteMatch } from "react-router-dom";

import NewArticle from "./NewArticle";
import BrowseArticles from "./BrowseArticles";
import { Route } from "react-router-dom";

import { connect } from "react-redux";
import { setOpenArticle, setArticles } from "../../actions";

function Blog({ articleIndex, articles }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return articles ? (
    <div className="blog-container">
      <Route exact path="/blog" component={NewArticle} />
      <Route exact path="/blog" component={BrowseArticles} />
    </div>
  ) : (
    <div className="blog-container">
      <div className="new-article-container"></div>
      <div className="blog-article-container"></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    articleIndex: state.setOpenArticle.articleIndex,
    articles: state.setArticles.articles,
  };
};

const mapDispatchToProps = {
  setOpenArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
