import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setOpenArticle, setArticles } from "../../actions";

function BlogArticle({ articles, articleIndex }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getArticlesCount = () => {
    let count = 0;
    articles.forEach((article) => {
      count++;
    });
    return count;
  };

  const getNewArticles = () => {
    const articlesCount = getArticlesCount();
    let newArticles = [];
    if (articlesCount > 1) {
      const pickedArticlesCount = 0;
      articles.forEach((category, categoryIndex) => {
        category.articles.forEach((article, index) => {
          if (pickedArticlesCount < 3) {
            newArticles.push(article);
          }
        });
      });
    }
  };

  //getNewArticles();
  if (!articles) {
    return <div className="blog-article-container"></div>;
  }

  const activeArticle = articles[articleIndex];

  return (
    <div className="blog-article-container">
      <div className="wrapper">
        <div className="article">
          <div className="article-category text-red-line text-red-line-absolute">
            <div className="line"></div> {articles[articleIndex].category}
          </div>
          <h1 className="article-title">{activeArticle.title}</h1>
          <div className="article-date">{activeArticle.date}</div>
          <div className="article-image" style={{ backgroundImage: `url('${activeArticle.image.url}')` }}></div>
          <div className="article-text gera" dangerouslySetInnerHTML={{ __html: activeArticle.description && activeArticle.description.html ? activeArticle.description.html : "" }}>
            {/* {articleText.map((text, index) => (
              <div className={`${text[0]}`} key={index}>
                {createQoute(text[0])}
                {createList(text[0], text[1])}
              </div>
            ))} */}
          </div>
        </div>
        {/* <div className="other-articles"></div> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    articleIndex: state.setOpenArticle.articleIndex,
    articles: state.setArticles.articles,
  };
};

const mapDispatchToProps = {
  setOpenArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogArticle);
