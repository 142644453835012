import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { setOpenArticle, setArticles } from "./actions";

import Header from "./components/Header";
import MainSections from "./components/MainSections";
import Blog from "./components/blog/Blog";
import BlogArticle from "./components/blog/BlogArticle";
import Footer from "./components/Footer";

import { language } from "./translations/language";

import "./App.css";

//"homepage": "https://www.hyperops.net",
//"homepage": "https://www.hyperops.pl",

import { GraphQLClient, gql } from "graphql-request";

const graphcms = new GraphQLClient("https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clguy0jkp08uc01umcuyp1w0u/master");
//https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clguy0jkp08uc01umcuyp1w0u/master
//old https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clg81qo7x1lwg01t3f49fbhe3/master
const QUERY = gql`
  {
    blogs(orderBy: publishedAt_DESC) {
      category
      createdAt
      date
      description {
        html
        raw
      }
      id
      publishedAt
      readTime
      title
      updatedAt
      image {
        url
      }
      priority
    }
  }
`;

function App({ articles, setArticles, articleIndex, setOpenArticle }) {
  useEffect(() => {
    async function getArticles() {
      await graphcms.request(QUERY).then((res) => {
        let blogs = res.blogs;

        console.log("gera");
        blogs.sort(function (a, b) {
          return a.priority - b.priority;
        });
        console.log(blogs[0].priority);
        console.log(blogs);
        setArticles(blogs);
      });
    }

    getArticles();
  }, [setArticles]);

  useEffect(() => {
    if (articles) {
      const url = window.location.href;
      let urlOrigin = window.location.origin;

      articles.forEach((article, articleIndex) => {
        let articleUrl = `${urlOrigin}/#/article-${articleIndex}`;
        if (articleUrl === url) {
          setOpenArticle({ articleIndex: articleIndex });
        }
      });
    }
  }, [setOpenArticle, articles]);

  return (
    <div className="App">
      <div className="website-wrapper">
        <Header />
        {language === "EN" ? (
          <Switch>
            <Route exact path={`/`} component={MainSections} />
            <Route path="/blog" component={Blog} />
            <Route path={`/article-${articleIndex}`} component={BlogArticle} />
            <Route path="*" component={MainSections} />
          </Switch>
        ) : null}
        <Footer />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    articleIndex: state.setOpenArticle.articleIndex,
    articles: state.setArticles.articles,
  };
};

const mapDispatchToProps = {
  setOpenArticle,
  setArticles,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
