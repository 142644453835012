import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { setOpenArticle, setArticles } from "../../actions";

import { RichText } from "@graphcms/rich-text-react-renderer";

function NewArticle({ setOpenArticle, articles, articleIndex }) {
  const [activeCategory, setActiveCategory] = useState(0);

  useEffect(() => {
    getNewArticle();
  }, []);
  useEffect(() => {
    getNewArticle();
  }, [activeCategory]);

  const articleCategories = articles.map((category) => {
    return category.title;
  });
  // articleCategories.unshift("All stories");

  const getNewArticle = () => {
    let newArticleId = 0;
    let newArticleIndex = 0;
    let selectedCategories = [];

    if (activeCategory !== 0) {
      selectedCategories.push(articles[activeCategory - 1]);
    } else {
      selectedCategories = articles;
    }

    selectedCategories.forEach((article, index) => {
      if (article.id > newArticleId) {
        newArticleId = article.id;
        newArticleIndex = index;
      }
    });

    setOpenArticle({ articleIndex: newArticleIndex });
  };

  if (!articles) {
    return <div className="new-article-container"></div>;
  }

  const activeArticle = articles[articleIndex];

  return (
    <div className="new-article-container">
      {/* <h1 className="blog-title">Stories we tell</h1>
      <div className="article-categories">
        <p className="discover">Discover our stories:</p>
        {articleCategories.map((category, index) => (
          <div
            className={`category ${activeCategory === index ? "active-category" : ""}`}
            key={index}
            onClick={() => {
              setActiveCategory(index);
            }}
          >
            {category}
          </div>
        ))}
      </div> */}

      <Link className="new-article" to={`/article-${articleIndex}`}>
        <div className="article-image" style={{ backgroundImage: `url('${activeArticle.image.url}')` }}></div>
        <div className="article-info">
          <div className="category text-red-line ">
            <div className="line"></div>
            {articles[articleIndex].category}
          </div>
          <h5 className="article-title">{activeArticle.title}</h5>
          <div className="article-date">{activeArticle.date}</div>
          <div className="article-text" dangerouslySetInnerHTML={{ __html: activeArticle.description.html }}></div>
          {/* <RichText className="article-text"content={activeArticle.description.raw} /> */}
        </div>
      </Link>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    articleIndex: state.setOpenArticle.articleIndex,
    articles: state.setArticles.articles,
  };
};

const mapDispatchToProps = {
  setOpenArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewArticle);
