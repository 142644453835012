import { combineReducers } from "redux";

import setOpenArticle from "./setOpenArticle";
import setScrollElement from "./setScrollElement";
import setArticlesLoaded from "./setArticlesLoaded";
import setArticles from "./setArticles";

export default combineReducers({
  setOpenArticle: setOpenArticle,
  setScrollElement: setScrollElement,
  setArticlesLoaded: setArticlesLoaded,
  setArticles: setArticles,
});
