export const texts = {
  // Header
  "How it works": ["Jak to działa"],

  "DevOps as a service": ["DevOps as a service"],

  "Benefits": ["Korzyści"],

  "Contact Us": ["Kontakt"],

  // Section 0

  "So you can work better, faster, and more efficiently": ["Pracuj lepiej, szybciej i wydajniej"],

  "Learn more": ["Dowiedz się więce"],

  // Section 1

  "How it <span>works</span><br> speed-up your business": ["Przyspiesz swój biznes"],

  "DevOps solutions allow increase of quality and speed of software development faster, easier and without a need to hire or re-engineer your internal processes. <br><br> At HyperOps, we thoroughly analyze your existing workflow and infrastructure, customize and integrate all of the tools your team needs, and then manage your migration.":
    [
      "Rozwiązania DevOps pozwalają na rozwój oprogramowania nie tylko lepszej jakości, ale również szybciej i wydajniej, a co najważniejsze bez konieczności reorganizacji wewnętrznych procesów w firmie.<br><br>W HyperOps dogłębnie przeanalizujemy Twoją obecną infrastrukturę i procesy, aby dostarczyć i wdrożyć odpowiednie narzędzia, dzięki którym Twój zespół płynnie przejdzie proces migracji - oczywiście z naszą pomocą!",
    ],

  "Easy access": ["Budowa i Transfer"],

  "Access our DevOps as a Service with just a few clicks via our dedicated web platform. We will outfit it with all the infrastructure and bespoke technical adjustments you need.": [
    "Usprawnienie i migracja obecnych praktyk DevOps w obrębie istniejącego środowiska(np. CI/CD, Monitoring, Logging, Containerisation)",
  ],

  "Integrations": ["Platformy DevOps"],

  "Easily integrate the workflow and task management applications that your team prefers in our one-stop-shop.": [
    "Prywatne, dedykowane platformy DevOps dostarczane na wiodących podzespołach w oparciu o sprawdzone rozwiązania",
  ],

  "CI/CD": ["Szkolenia"],

  "We’ve already pre-integrated the best CI/CD practices and processes so you can use one out of the box right away, or build it to fit your needs.": [
    "Szkolenie pracowników DevOps z zakresu najlepszych praktyk i wykorzystania odpowiednich narzędzi",
  ],

  "Users": ["Dostęp"],

  "Manage your team's access, permissions, and scope. Plus, every action your team performs is logged for clear oversight and full transparency.": [
    "Wygodne zarządzanie uprawnieniami użytkowników w zakresie dostępu, zezwoleń i kompetencji. Dodatkowo, każda akcja użytkownika jest zapisywana co pozwala na przejrzystość i łatwe analizowanie.",
  ],

  "Environments": ["Środowisko"],

  "Fully customize your development, staging UAT, and production environments to your needs. Whether they’re identical or individualized, you can access them all within a single sign-on.": [
    "Konfiguruj środowiska testowe, rozwojowe i produkcyjne wedle bieżących potrzeb",
  ],

  "Infrastructure": ["Infrastruktura"],

  "Unlike public clouds where you have no real support or have to purchase it separately, at HyperOps, you have access to ready to use servers, networks, Kubernetes clusters, backups, and HA solutions with 99.99% uptime SLA and full support included.":
    ["Rozwiązania DevOps dla chmur publicznych (AWS, Microsoft Azure, Google Cloud) lub na wewnętrznych data center z dostępnością 99.99%, pełnym SLA i wsparciem"],

  // Section 2
  "The difference of<br> HyperOps": ["Różnica dzięki<br>HyperOps"],

  "Our DevOps Service is backed by the latest technology and best integrations, but it is our expert insight and committed support that allows you to maximize our platform to the fullest.": [
    "Nasze usługi DevOps dostarczamy z wykorzystaniem najnowszych technologii, ale to nasze profesjonalne wsparcie poparte doświadczeniem przy pracy z naszymi klientami pozwala na wykorzystanie rozwiązań DevOps do maksimum",
  ],

  "Fully-supported platforms": ["Platformy z pełnym wsparciem"],

  "Our DevOps as a Service platform gives your team the tools to collaborate and be more agile than ever before. But, it is our custom solutions, dedicated project managers, and 24/7/365 support that makes us stand out and allows you to stay ahead.":
    [
      "Nasze rozwiązania DevOps umożliwią Twojemu zespołowi na wyjątkowo wydajną współpracę i responsywność. W tym czasie to my dostarczymy Ci narzędzia, rozwiązania, dedykowanych project managerów i wsparcia 24/7/365, dzięki czemu Twój zespół może skupić się na naprawdę ważnych zadaniach",
    ],

  "Cloud of your choise": ["Chmura"],

  "You can use your own private cloud or a public cloud of your choice. At HyperOps, we have ready to use servers, networks, Kubernetes clusters, backups, and HA solutions with 99.99% uptime SLA that are fully supported by our team of experts.":
    ["Wybierz Chmurę Prywatną lub Publiczną - my dostarczymy Ci serwery, sieć, backupy i Wysoką Dostępność na poziomie 99.99% z pełnym wsparciem naszego zespołu"],

  "Expert insight": ["Porada eksperta"],

  "We go further by not only providing a top-tier DevOps as a Service platform but by designing bespoke solutions and supporting your team with expert insights so they can code, test and deploy more efficiently from day one and beyond.":
    ["Oprócz dostarczenia topowych usług DevOps, zaprojektujemy dla Twojej firmy optymalne rozwiązania i wesprzemy naszym doświadczeniem, aby Twój zespół rozwijał, testował i wdrażał wydajniej "],
  // Section 3
  "Our full-support<br> behind the front-end": ["Pełne wsparcie Twoich kluczowych usług"],

  "Blue-green deployment": ["Wdrożenia blue-green"],

  "Full-scope of needed environments": ["Pełen zakres potrzebnych środowisk"],

  "Handy resource accounting via performance graphs and logs": ["Statystyki wydajności"],

  "99.99% uptime": ["Dostępność 99.99%"],

  "Effortlessly track, trace and change management": ["Wygodne monitorowainej i modyfikowanie zarządzana"],

  "Zero downtime on application deployments and updates": ["Bez przestoju w Twoich usługach, wdrożeniach i testach"],

  "Prompt and easy roll-back to any previous version": ["Łatwa procedura przywracania do poprzedniej wersji"],

  "24x7x365 support with a dedicated project manager": ["Wsparcie 24/7/365 i dedykowany project manager"],

  // Section 4

  "Everything you need": ["Wszystko czego potrzebujesz"],

  "to improve collaboration, monitoring, management, and reporting": ["Usprawnij kooperację, zarządzanie, raportowanie i monitorowanie"],

  "Instant access to DevOps professionals": ["Natychmiastowy dostęp do ekspertów DevOps"],

  "from the start and on-call when you need us.": ["jesteśmy do Twojej dyspozycji zawsze kiedy tego wymagasz."],

  "Expertise, technical documentation, and mentorship": ["Doświadczenie, dokumentacja, mentoring"],

  "to help your in-house team continually develop and improve.": ["czyli kompleksowe wsparcie rozwoju Twojego zespołu"],

  "Fine-tuned workflow deployment": ["Wdrożenie optymalnego przepływu pracy"],

  "to streamline your code transitions into intuitive, quickly-executed tasks.": ["pozwalającego na dokonywanie sprawnych zmian w kodzie"],

  "New and improved processes": ["Rozwój Twoich wewnętrznych procesów DevOps"],

  "extending your internal DevOps capacity while enabling our resources and practices.": ["wsparty naszymi zasobami i wypracowanymi praktykami"],

  "Exciting infrastructure capabilities": ["Ekscytujące możliwości infrastruktury"],

  "with full auto-scaling power and optimized resource utilization.": ["z pełną mocą auto-skalowania, oraz z zoptymalizowanym wykorzystaniem zasobów."],

  "24x7x365 support": ["Dostępność i wsparcie 24/7/365"],

  "provided by our DevOps team of experts.": ["Tak działa nasza infrastruktura i zespół ekspertów"],

  // Section 5

  "Full house<br> of baseline components": ["Pełen wachlarz podstawowych narzędzi"],

  "Kubernetes": ["Kubernetes"],

  "Managed Kubernetes and container orchestration.": ["Zarządzanie i nadzorowanie Kubernetes"],

  "Databases": ["Bazy Danych"],

  "Choose the most suitable DB: SQL, NoSQL, etc.": ["Wybierz odpowiednią dla siebie: SQL, NoSQL itd"],

  "GitLab": ["GitLab"],

  "GitLab for CI/CD pipeline, users and projects management.": ["GitLab dla kolejki CI/CD, użytkowników i zarządzania projektami"],

  "Kibana / Graylog": ["Kibana / Graylog"],

  "Easily manage and search your logs.": ["Łatwo przeszukuj i magazynuj swoje logi"],

  "Grafana": ["Grafana"],

  "Grafana/Prometheus monitoring and dashboards.": ["Grafana/Prometheus monitorowanie i podgląd"],

  "Ansible": ["Ansible"],

  "Ansible for virtual instances' management.": ["Ansible dla zarządzania wirtualizacją"],

  // Section 6

  "We’re setting you up for success": ["Wspieramy Twój sukces!"],

  "Infrastructure analysis": ["Analiza Twojej infrastruktury"],

  "Our team will first examine your current workflow and capacities so we can maximize your transition into DevOps as a Service.": [
    "Przeanalizujemy Twoje wewnętrzne procesy i pracę aby przejście do systemu DevOps przyniosło najlepsze efekty",
  ],

  "Consultation": ["Dialog"],

  "Together, we’ll come up with a comprehensive migration plan for your infrastructure, people, and processes.": [
    "Wspólnie opracujemy kompleksowy plan migracji dla Twojej infrastruktury, procesów i załogi",
  ],

  "Migration plan defined": ["Plan Migracji"],

  "Together we will define migration plan to give you a clear view and understanding what and when will be done and how this will impact your operations.": ["Weryfikacja i akceptacja planu migracji"],

  "Migration": ["Migracja"],

  "Our experts will make sure it’s a smooth transition, implementing CI/CD, load balancing, autoscaling, DR, backup, and monitoring solutions for you.": [
    "Z nami proces migracji jest płynny - wykorzystujemy wdrożenia CI/CD, rozkład obciążenia, automatyczne skalowanie, Disaster Recovery oraz rozwiązania monitorujące",
  ],

  "Going live": ["Jesteśmy na żywo!"],

  "Once we deploy the data and code, you’re on board. Now, your team can focus and enjoy the benefits of DevOps as a Service, continually improving development, deployment, and maintenance.": [
    "Po wdrożeniu Twoich danych i kodu możesz startować! Teraz Twój zespół może w pełni skupić się na swojej pracy i dzięki usługom DevOps nieprzerwanie usprawniać rozwój, wdrożenia i działanie usług",
  ],

  // Section 7

  "We stand by our clients and they stand by us": ["Wspieramy naszych klientów, a Oni wspierają nas"],

  "Since 2004, HyperOps has served more than 120+ clients and logged more than 300k working hours. We are also proud to say that 99% of potential clients who test our services stay with us long term.":
    ["Od 2004 roku, HyperOps obsługiwało przeszło 120 klientów i wykonało ponad 300 tysięcy godzin usług. 99% klientów po wypróbowaniu naszych usług, zostaje z HyperOps na stałe. "],

  "Our packages<br> are just a click away": [""],

  "HyperOps offers a wide selection of packages to fit every company’s needs. And if you don’t see exactly what you’re looking for here, we will design a tailored solution just for you.": [""],

  "Our web applications provide time-sensitive information to our global financial industry customers, and as such, they have to meet the highest requirements for availability and security at all times. HyperOps team’s technical assistance and knowledge of our business logic are invaluable and their dedication to keeping systems running 24×7 is unmatched.":
    [
      "Nasze aplikacje dostarczają danych wrażliwych dla klientów z branży finansów międzynarodowych, dlatego muszą spełniać najwyższe standardy dostępności i bezpieczeństwa. Zespół HyperOps dostarcza kompleksowe wsparcie, a także doskonale rozumie nasz biznes i jego wymagania, dlatego nasza współpraca działa bardzo płynnie. Dzięki ich poświęceniu nasze systemy pracują zawsze, 24 godziny na dobę",
    ],

  "Karl Mallalieu": ["Karl Mallalieu"],

  "We are extremely happy with the quality of the services and the high technical standards provided by Metasite HyperOps as well as their responsiveness in the day-to-day business.": [
    "Jesteśmy wyjątkowo zadowoleni z usług i wysokich standardów technicznych dostarczanych przez HyperOps, szczególnie z ich niespotykanej responsywności każdego dnia.",
  ],

  "Dr. Jochen Kraatz": ["Dr. Jochen Kraatz"],

  "Their incident reaction times were almost always under 10 minutes. I can confidently recommend HyperOps to anyone in need of the flawless operation of their critical systems. We are very happy with our relationship and the level of services HyperOps provides":
    [
      "Chyba na każde zgłoszenie odpowiadają w przeciągu 10 minut. Mogę polecić HyperOps każdemu kto wymaga niezakłóconej pracy swoich kluczowych usług. Bardzo cieszy nas kooperacja z HyperOps i poziom usług które dostarczają.",
    ],

  "Algirdas Petrauskas": ["Algirdas Petrauskas"],

  // Section 9

  "Leave your details below to speak to one of our experts about the best DevOps solution for your business.": [
    "Wypełnij formularz a skontaktujemy się z Tobą w sprawie najlepszych rozwiązań DevOps dla Twojej firmy!",
  ],

  // Footer

  "HyperOps offers know-how IT solutions and DevOps services with exclusive 24/7/365 coverage. For more than 15 years company is serving the most demanding financial, telco, media, and e-commerce enterprises keeping a strong track record and one of the fastest response times within the market.":
    [
      "HyperOps oferuje know-how z zakresu rozwiązań IT i DevOps z dedykowanym dostępem 24/7/365. Od przeszło 15 lat dostarczamy nasze usługi dla najbardziej wymagających klientów z branż finansowych, telekomunikacyjnych, medialnych oraz e-commerce. Mamy bogate doświadczenie i jeden z najlepszych czasów reakcji na zgłoszenia na rynku!",
    ],

  "Advantages": ["Zalety"],

  "Back-end": ["Back-end"],

  "Values": ["Korzyści"],

  "Components": ["Składniki"],

  "Migration plan": ["Składniki"],

  "Our clients": ["Nasi klienci"],

  "All rights reserved": ["Wszelkie prawa zastrzeżone"],

  "Your email address": ["Twój adres e-mail"],

  "Message (optional)": ["Wiadomość (opcjonalne)"],

  "Request price": ["Zapytaj o nasze usługi"],

  "Email sent successfully": ["Wiadomość wysłana"],
};
