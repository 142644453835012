const initialState = {
  articles: null,
};

const setArticles = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ARTICLES":
      return { ...state, articles: action.payload };
    default:
      return state;
  }
};

export default setArticles;
