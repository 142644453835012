const sectionTitlesData = [
  {
    heading: `How it <span>works</span><br> speed-up your business`,
    text: `DevOps solutions allow increase of quality and speed of software development faster, easier and without a need to hire or re-engineer your internal processes. <br><br> At HyperOps, we thoroughly analyze your existing workflow and infrastructure, customize and integrate all of the tools your team needs, and then manage your migration.`,
  },
  {
    heading: `The difference of<br> HyperOps`,
    text: `Our DevOps Service is backed by the latest technology and best integrations, but it is our expert insight and committed support that allows you to maximize our platform to the fullest.`,
  },
  {
    heading: `Our full-support<br> behind the front-end`,
    text: ``,
  },
  {
    heading: `Everything you need`,
    text: `to improve collaboration, monitoring, management, and reporting`,
  },
  {
    heading: `Full house<br> of baseline components`,
    text: ``,
  },
  {
    heading: `We’re setting you up for success`,
    text: ``,
  },
  {
    heading: `We stand by our clients and they stand by us`,
    text: `Since 2004, HyperOps has served more than 120+ clients and logged more than 300k working hours. We are also proud to say that 99% of potential clients who test our services stay with us long term.`,
  },
  {
    heading: `Our packages<br> are just a click away`,
    text: `HyperOps offers a wide selection of packages to fit every company’s needs. And if you don’t see exactly what you’re looking for here, we will design a tailored solution just for you.`,
  },
];

export default sectionTitlesData;
