import { language } from "./language";
import { texts } from "./texts";

export const t = (text) => {
  if (language === "PL") {
    if (text in texts) {
      text = texts[text][0];
    }
  }

  return text;
};
