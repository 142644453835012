const initialState = {
  articlesLoaded: 0,
};

const setArticlesLoaded = (state = initialState, action) => {
  switch (action.type) {
    case "SET_OPEN_ARTICLE":
      return { ...state, articlesLoaded: action.payload };
    default:
      return state;
  }
};

export default setArticlesLoaded;
