import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";

import { connect } from "react-redux";
import { setScrollElement } from "../actions";

import { t } from "../translations/translations";
import { language } from "../translations/language";

function Header({ setScrollElement }) {
  const history = useHistory();
  const [burgerClicked, setBurgerClicked] = useState(false);

  const scrollToSection = (element) => {
    setBurgerClicked(false);
    setScrollElement(element);
    if ($("." + element).offset() === undefined) {
      history.push("/");
    }
  };

  let headerButtons = [
    ["logo-image", "", "section0"],
    ["btn", "How it works", "section1"],
    ["btn", "DevOps as a service", "section2b"],
    ["btn", "Benefits", "section4"],
    ["btn", "Packages", "section8", "hidden"],
    ["btn", "Blog", "blog", "hidden"],
  ];

  headerButtons = language === "PL" ? headerButtons.filter((button) => button[3] !== "hidden") : headerButtons;

  const createMenuButtons = () => {
    return headerButtons.map((button, index) => {
      return button[2] === "blog" ? (
        <Link className={`btn`} key={index} to={`/blog`} onClick={() => scrollToSection(button[2])}>
          {t(button[1])}
        </Link>
      ) : (
        <div className={`${button[0]}`} key={index} onClick={() => scrollToSection(button[2])}>
          {t(button[1])}
        </div>
      );
    });
  };

  const createConctactButtons = () => {
    return (
      <div className={`container container-contact ${language !== "PL" ? "" : "justify-center"}`}>
        {language !== "PL" ? (
          <div className="btn btn-round btn-hollow btn-demo" onClick={() => scrollToSection("section7")}>
            {t("Demo")}
          </div>
        ) : null}
        <div className="btn btn-round  btn-contact" onClick={() => scrollToSection("footer")}>
          {t("Contact Us")}
        </div>
      </div>
    );
  };

  let burgerClassName = burgerClicked ? "burger-menu-clicked" : null;

  return (
    <div className="header">
      <div className="navbar">
        <div className="container-left container">{createMenuButtons()}</div>
        {createConctactButtons()}
        <div className={"burger-menu " + burgerClassName} onClick={() => setBurgerClicked(!burgerClicked)}>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
      <div className="sidebar" style={{ transform: burgerClicked ? "translateX(0%)" : "translateX(-100%)" }}>
        {createMenuButtons()}
        {createConctactButtons()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    articleIndex: state.setOpenArticle.articleIndex,
  };
};

const mapDispatchToProps = {
  setScrollElement,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
