import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { setOpenArticle } from "../../actions";

function BrowseArticles({ setOpenArticle, articles }) {
  // useEffect(() => {
  //   const categoriesCount = blogArticles.length;
  //   const categoriesOpen = Array(categoriesCount).fill(false);
  //   setOpenCategoryArticles(categoriesOpen);
  // }, []);

  //const [openCategoryArticles, setOpenCategoryArticles] = useState(null);

  // const viewButtonClicked = (index) => {
  //   let openCategoryArticlesCopy = [...openCategoryArticles];
  //   openCategoryArticlesCopy[index] = !openCategoryArticlesCopy[index];
  //   setOpenCategoryArticles(openCategoryArticlesCopy);
  // };

  // const categoryRefs = useRef([]);
  return (
    <div className="browse-articles-container">
      <div className="article-category-container">
        <div className="container-top">
          {/* <div className="category text-red-line text-red-line-absolute">
                <div className="line"></div>
                {category.title}F
              </div>
              <div className="btn btn-round btn-hollow" style={{ display: articlesCount > 3 ? "flex" : "none" }} onClick={() => viewButtonClicked(categoryIndex)}>
                {openContainer ? "View less" : "View more"}
              </div> */}
        </div>
        <div className="container-bottom">
          <div className="overflow-wrapper">
            {articles.map((article, articleIndex) => (
              <Link
                className="article-container"
                key={articleIndex}
                style={{
                  marginRight: (articleIndex + 1) % 3 === 0 ? "0px" : "20px",
                }}
                to={`/article-${articleIndex}`}
                onClick={() => {
                  setOpenArticle({ articleIndex: articleIndex });
                }}
              >
                <div className="article-image" style={{ backgroundImage: `url('${article.image.url}')` }}></div>
                <h6 className="article-title">{article.title}</h6>
                <div className="article-date">{`${article.date} - ${article.readTime} min read`}</div>
              </Link>
            ))}
          </div>
        </div>
        <div className="line-grey"></div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    articleIndex: state.setOpenArticle.articleIndex,
    articles: state.setArticles.articles,
  };
};

const mapDispatchToProps = {
  setOpenArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseArticles);
